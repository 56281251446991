import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import RichText from '../components/richtext';

const LegalNoticePage = () => {
  const data = useStaticQuery(graphql`
    query LegalNotice {
      legalNotice: allSanityLegalNotice {
        edges {
          node {
            title
            _rawContent
          }
        }
      }
    }
  `);

  const legalNotice = data.legalNotice?.edges.map(edge => edge.node);

  return (
    <Layout>
      <SEO siteTitle={legalNotice[0].title} />
      <RichText
        key={legalNotice[0].title}
        blocks={legalNotice[0]._rawContent}
      />
    </Layout>
  );
};

export default LegalNoticePage;
